<template>
  <q-page padding>
    <q-banner
      rounded
      transition-show="jump-down"
      class="bg-info text-white"
    >
      <p class="text-h6">Obrigado por se registrar!</p>
      <p>
        Por favor confirme seu email para finalizar o cadastro: <b>{{ $route.query.email }}</b>.
      </p>
      <template v-slot:action>
        <q-btn
          flat
          color="white"
          label="Voltar"
          :to="{name: 'login'}"
          icon="arrow_back"
        />
      </template>
    </q-banner>
  </q-page>
</template>
<script>
import { defineComponent, ref } from 'vue'
import useAuthUser from '../composables/UseAuthUser'
import { useRouter } from 'vue-router'
import useNotify from '../composables/UseNotify'

export default defineComponent({
  name: 'PageEmailConfirmation',

  setup () {
    const router = useRouter()

    const { register } = useAuthUser()

    const { notifyError } = useNotify()

    const form = ref({
      name: '',
      email: '',
      password: ''
    })

    const handleRegister = async () => {
      try {
        await register(form.value)
        router.push({ name: 'email-confirmation', query: { email: form.value.email } })
      } catch (error) {
        notifyError(error.message)
      }
    }

    return { form, handleRegister }
  }
})
</script>
